<template>
  <b-modal
      id="kpi-form-modal"
      centered
      hide-footer
      size="md"
      :title="`${!editedKpi ? 'Add new KPI' : 'Edit KPI'}`"
      @shown="onModalShown"
      @hidden="onModalHidden"
  >
    <div v-if="editedKpi" class="text-right">
      <b-button variant="outline-danger" size="sm" @click.prevent="deleteKPI">
        Delete KPI
      </b-button>
    </div>

    <validation-observer ref="saveKpiValidation">
      <b-form @submit.prevent="handleFormSubmit">
        <validated-form-input
            class="base-dashboard-form-group"
            label="KPI Name"
            input-id="kpi-name-input"
            validation-name="kpi name"
            v-model="name"
            placeholder="Enter KPI Name"
        />

        <b-form-group class="base-dashboard-form-group" label="Rules" label-for="rules-input">
          <transition-group name="fade">
            <div class="row mb-1" v-for="(rule, ruleIdx) in rules" :key="rule.id || rule._id">
              <div class="col-5 pr-50">
                <validation-provider
                    rules="required"
                    #default="{errors}"
                    :name="`rule column_${rule.id || rule._id}`"
                >
                  <b-form-select
                      class="dashboard-base-select hide-arrows"
                      v-model="rule.column"
                      :state="errors.length ? false : null"
                      :options="columnOptions"
                  />
                </validation-provider>
              </div>
              <div class="col-3 px-50">
                <validation-provider
                    rules="required"
                    #default="{errors}"
                    :name="`rule operator_${rule.id || rule._id}`"
                >
                  <b-form-select
                      class="dashboard-base-select hide-arrows"
                      v-model="rule.operator"
                      :state="errors.length ? false : null"
                      :options="operatorOptions"
                  />
                </validation-provider>
              </div>
              <div class="col-4 pl-50">
                <div class="d-flex align-items-center">
                  <validated-form-input
                      :show-error-msg="false"
                      :validation-name="`rule value_${rule.id || rule._id}`"
                      :class="{ 'mr-50': ruleIdx !== 0 }"
                      placeholder="Value"
                      v-model="rule.value"
                      type="number"
                  />
                  <b-button
                      v-if="ruleIdx !== 0"
                      @click.prevent="deleteRule(ruleIdx)"
                      variant="flat-danger"
                      class="btn-icon">
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </div>
              </div>
            </div>
          </transition-group>
          <a href="#" class="d-inline-flex align-items-center" @click.prevent="addNewRule">
            <feather-icon icon="PlusIcon" class="mr-50"/>
            <span class="align-middle">Add new rule</span>
          </a>
        </b-form-group>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click.prevent="closeModal" variant="outline-secondary" class="mr-1">
            Cancel
          </b-button>
          <b-button type="submit" variant="primary" :disabled="loading">
            <template v-if="loading">
              <b-spinner small class="mr-50"/>
              <span class="align-middle">Loading</span>
            </template>
            <span v-else>{{ editedKpi ? 'Edit' : 'Create' }} KPI</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {mapState} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {BModal, BForm, BFormGroup, BButton, BSpinner, BFormSelect} from 'bootstrap-vue';
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import ValidatedFormSelect from "@/layouts/components/shared/ValidatedFormSelect";
import toastsMixin from "@/mixins/toastsMixin";

export default {
  name: 'KpiFormModal',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    ValidatedFormInput,
    ValidatedFormSelect
  },
  mixins: [toastsMixin],
  data() {
    return {
      name: '',
      rules: [],
      loading: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapState('dashboard', ['editedKpi']),

    columnOptions() {
      const options = this.user.location_data_columns.map(({key, label}) => ({value: key, text: label}));
      options.unshift({
        value: null,
        text: 'Column',
        disabled: true
      });
      return options;
    },

    operatorOptions: () => {
      return [
        {
          value: null,
          text: 'Operator',
          disabled: true
        },
        {
          value: '>',
          text: 'more than',
        },
        {
          value: '<',
          text: 'less than'
        }
      ];
    }
  },
  methods: {
    mounted() {
      if (this.rules.length) return;
      this.addNewRule(false);
    },
    onModalShown() {
      if (!this.editedKpi) return;

      this.name = this.editedKpi.name;
      this.rules = this.editedKpi.rules;
    },
    onModalHidden() {
      this.name = null;
      this.rules = [{
        column: null,
        operator: null,
        value: null
      }];
    },
    async addNewRule(runValidations = true) {
      if (!runValidations) {
        this.rules.push({
          _id: uuidv4(),
          column: null,
          operator: null,
          value: null
        });
        return;
      }

      const isValid = await this.$refs.saveKpiValidation.validate();

      if (!isValid) return;

      this.rules.push({
        _id: uuidv4(),
        column: null,
        operator: null,
        value: null
      });
    },
    deleteRule(ruleIdx) {
      this.rules.splice(ruleIdx, 1);
    },
    resetForm() {
      this.name = null;
      this.rules = [{
        column: null,
        operator: null,
        value: null
      }];
      this.$refs.saveKpiValidation.reset();
    },
    closeModal() {
      this.$bvModal.hide('kpi-form-modal')
    },
    async createKPI() {
      await this.$store.dispatch('dashboard/createKPI', {
        name: this.name,
        rules: this.rules,
        relatedAccountId: this.$store.state.auth.user.related_account_id
      });
      this.showSuccessToast('KPI Successfully created');
      this.resetForm();
      this.closeModal();
      await this.$store.dispatch('dashboard/fetchLocationsData');
    },
    async editKPI() {
      await this.$store.dispatch('dashboard/updateKPI', {
        ...this.editedKpi,
        name: this.name,
        rules: this.rules
      });
      this.showSuccessToast('KPI Successfully updated');
      this.closeModal();
      await this.$store.dispatch('dashboard/fetchLocationsData');
    },
    async handleFormSubmit() {
      const isFormValid = await this.$refs.saveKpiValidation.validate();
      console.log(isFormValid);

      if (!isFormValid || this.loading) return;

      try {
        this.loading = true;
        await (!this.editedKpi ? this.createKPI : this.editKPI)();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.showErrorToast('Something went wrong');
      }
    },
    async deleteKPI() {
      const {isConfirmed} = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!isConfirmed) return;

      await this.$store.dispatch('dashboard/deleteKPI', this.editedKpi.id);

      this.closeModal();

      this.$swal({
        icon: 'success',
        title: 'Deleted!',
        text: 'Your KPI has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
    }
  }
}
</script>
