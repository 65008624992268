<template>
  <div class="container">
    <div class="dashboard-content">
      <kpi-form-modal/>

      <dashboard-cards/>

      <locations-data-table/>
    </div>
  </div>
</template>

<script>
import {BDropdown, BDropdownItem, BFormCheckbox} from 'bootstrap-vue';
import {Skeleton} from "vue-loading-skeleton";
import {mapState, mapActions} from 'vuex';
import DashboardCards from "@/layouts/components/front/dashboard/DashboardCards";
import LocationsDataTable from "@/layouts/components/front/dashboard/LocationsDataTable";
import KpiFormModal from "@/layouts/components/front/dashboard/KpiFormModal";

export default {
  name: 'Dashboard',
  components: {
    DashboardCards,
    LocationsDataTable,
    Skeleton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    KpiFormModal
  },
  data() {
    return {
      initialLoad: true,
    }
  },
  async mounted() {
    this.setVisibleColumnsFromLocalStorage();

    this.initialLoad = true;

    if (!this.timeframes.length) {
      await this.fetchTimeframes();
    }

    await Promise.all([
      this.fetchTopStats(),
      this.fetchLocationsData(),
      this.fetchKeyPerformanceIndicators()
    ]);

    this.initialLoad = false;
  },
  computed: {
    ...mapState('dashboard', [
      'currentTimeframe',
      'timeframes'
    ]),

    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('dashboard', [
      'fetchTimeframes',
      'fetchTopStats',
      'fetchLocationsData',
      'refetchLocationsData',
      'fetchKeyPerformanceIndicators'
    ]),

    setVisibleColumnsFromLocalStorage() {
      const persistedVisibleColumnsInStorage = localStorage.getItem('dashboard_visible_columns');

      this.$store.commit(
          'dashboard/SET_VISIBLE_LOCATIONS_TABLE_COLUMNS',
          persistedVisibleColumnsInStorage
              ? JSON.parse(persistedVisibleColumnsInStorage)
              : this.user.location_data_columns
      );
    },
  },
  watch: {
    currentTimeframe() {
      if (this.initialLoad) return;

      this.fetchTopStats();
      this.refetchLocationsData();
    }
  }
}
</script>
