<template>
  <div :class="'dashboard-card '+ titleClass"
       @scroll="expandCard"
  >
    <div class="dashboard-card-title">
      <img :src="`/emoji/${emoji}.png`" :alt="`${emoji}-icon`">
      <div>{{ title }}</div>
    </div>
    <div v-if="!rowsData.length" class="card-no-data">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
           class="feather feather-activity">
        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"/>
      </svg>
      <div>
        No data available yet
      </div>
    </div>
    <div class="card-info-row" v-for="(dataRow, dataRowIdx) in rowsDataSliced" :key="dataRowIdx">
      <transition name="fast-fade" mode="out-in">
        <div class="row-initials" v-if="!rowsDataLoading">{{ dataRowIdx + 1 }}</div>
        <Skeleton v-else class="initials-skeleton" loading="true"/>
      </transition>

      <transition name="fast-fade" mode="out-in">
        <div v-if="!rowsDataLoading" key="row_content" class="row-content-mid">
          <template v-if="['leads', 'revenue'].includes(dataType)">
            <div class="main-text">{{ dataRow.location.name }}</div>
            <div class="secondary-text">{{ dataRow.location.address }}</div>
          </template>
          <template v-else-if="dataType === 'appointments'">
            <div class="main-text">{{ dataRow.location_name }}</div>
            <div class="secondary-text">{{ dataRow.location_address }}</div>
          </template>
        </div>
        <div v-else key="row_skeleton" class="row-content-mid skeleton-wrapper">
          <Skeleton class="main-text-skeleton" style="width: 80%; display: block" loading="true"/>
          <Skeleton class="secondary-text-skeleton" loading="true"/>
        </div>
      </transition>

      <transition name="fast-fade" mode="out-in">
        <div v-if="!rowsDataLoading" key="row_content" class="row-content-right">
          <div v-if="dataType === 'leads'" class="success-text">{{ dataRow.total }} leads</div>
          <div v-else-if="dataType === 'appointments'" class="success-text">{{ dataRow.total_count }} appts.
          </div>
          <div v-else-if="dataType === 'revenue'" class="success-text">{{ dataRow.revenue }}$</div>
          <!--          <div class="secondary-text">+2.5%</div>-->
        </div>
        <div v-else key="row_skeleton" class="row-content-right skeleton-wrapper">
          <Skeleton class="success-text-skeleton" loading="true"/>
          <Skeleton class="secondary-text-skeleton" loading="true"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {Skeleton} from 'vue-loading-skeleton';
import gsap from "gsap";

export default {
  name: 'DashboardCard',
  data() {
    return {
      card_element: null,
      card_element_original_width: null,
      card_element_original_height: null,
      is_expaneded: false,
      rows_shown: 3,
      expand_timeout: null
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    emoji: {
      type: String,
      required: true
    },
    dataType: {
      type: String,
      required: true
    },
    rowsData: {
      type: Array,
      required: true
    },
    rowsDataLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Skeleton
  },
  methods: {
    generateRandomNumber: (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    expandCard() {
      this.card_element.style.zIndex = '50';
      if (this.expand_timeout) {
        return;
      }
      this.expand_timeout = setTimeout(() => {
        this.is_expaneded = true;

        const offsetTop = this.card_element.getBoundingClientRect().top;
        this.card_element.style.height = this.card_element_original_height + 'px';
        this.card_element.style.width = this.card_element_original_width + 'px';
        this.card_element.style.maxHeight = 'calc(100vh - ' + offsetTop + 'px)'
        this.card_element.style.position = 'absolute';

        // Gettign average height of row + margin top
        const row_element = this.card_element.querySelector('.card-info-row');
        const row_height = parseInt(window.getComputedStyle(row_element).getPropertyValue('margin-top').replace("px", "")) + row_element.offsetHeight;

        // console.log(this.card_element_original_height + ((this.rowsData.length - 3) * row_height))

        // Display the overlay
        this.overlay_element.style.display = 'block';
        this.rows_shown = 100;
        gsap.to(this.card_element, {
          duration: 0.3,
          height: (this.card_element_original_height + ((this.rowsData.length - 3) * row_height)) + 'px',
          ease: 'power3.inOut',
        })
        gsap.to(this.overlay_element, {
          duration: 0.3,
          opacity: 1,
        })

        this.is_expaneded = true;
      }, 800);

    },
    collapseCard() {
      clearTimeout(this.expand_timeout);
      this.expand_timeout = null;
      this.is_expaneded = false;

      gsap.to(this.overlay_element, {
        duration: 0.3,
        opacity: 0,
      })
      gsap.to(this.card_element, {
        duration: 0.3,
        height: this.card_element_original_height,
        width: this.card_element_original_width,
        ease: 'power3.inOut',
      })
      this.card_element.style.zIndex = '0';
      setTimeout(() => {
        this.overlay_element.style.display = 'none'
      }, 300);
      setTimeout(() => {
        this.card_element.style.position = 'relative';
        this.rows_shown = 3;
      }, 600);
    }
  },
  mounted() {
    this.card_element = document.querySelector('.' + this.titleClass);
    this.overlay_element = document.querySelector('.overlay-dark-screen');
    this.card_element_original_width = this.card_element.clientWidth;
    this.card_element_original_height = this.card_element.clientHeight;
    this.card_element.style.zIndex = '50';

  },
  computed: {
    titleClass() {
      return this.$props.title.toLowerCase().replace(/ /g, '-')
    },
    rowsDataSliced() {
      return this.rowsData.slice(0, this.rows_shown)
    }
  },
}
</script>
