<template>
  <div>
    <div class="locations-data-table-card">
      <div class="base-table-card-heading pb-0">
        <div class="d-flex align-items-start justify-content-between">
          <div>
            <div class="table-heading">All Accounts</div>
            <div class="table-subheading">See stats about {{ sortedLocationsData.length }} accounts</div>
          </div>

          <div class="d-flex align-items-center justify-content-center">

            <div>
              <b-dropdown
                  text="KPIs"
                  right
                  class="kpis-dropdown mr-1"
                  variant="outline-secondary"
              >
                <div class="dropdown-item">
                  <a href="#" class="d-block" @click.prevent="openCreateKpiModal">
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Add new KPI</span>
                  </a>
                </div>

                <b-dropdown-divider/>

                <div v-if="!keyPerformanceIndicators.length" class="dropdown-item">
                  There are currently no KPIs.
                </div>
                <div v-else v-for="kpi in keyPerformanceIndicators" :key="kpi.name"
                     class="dropdown-item kpi-dropdown-item">
                  <b-form-checkbox
                      class="pr-1"
                      :checked="!!kpi.is_active"
                      @change="isActive => toggleKpi(kpi, isActive)"
                  >
                    {{ kpi.name }}
                  </b-form-checkbox>
                  <a href="#" class="edit-kpi-btn" @click.prevent="openEditKpiModal(kpi)">
                    <feather-icon icon="Edit2Icon"/>
                  </a>
                  <a href="#" class="edit-kpi-btn text-danger" @click.prevent="deleteKPI(kpi)">
                    <feather-icon icon="TrashIcon"/>
                  </a>
                </div>
              </b-dropdown>

              <b-dropdown
                  id="cols-dropdown"
                  text="Columns"
                  right
                  class="toggle-columns-dropdown mr-1"
                  variant="outline-secondary"
              >
                <div class="dropdown-item" v-for="column in user.location_data_columns" :key="column.order">
                  <b-form-checkbox
                      @change="checked => toggleColumnVisibility(column, checked)"
                      :checked="isColumnVisible(column)"
                  >
                    {{ column.label }}

                  </b-form-checkbox>
                </div>
              </b-dropdown>
              <b-dropdown
                  id="cols-dropdown"
                  text="Per Page"
                  right
                  class="toggle-columns-dropdown"
                  ref="perPageDropdown"
                  variant="outline-secondary"

              >
                <div class="dropdown-item"
                     v-for="number in perPageOptions" :key="number"
                     @click="setPerPage(number)"

                >
                  {{ number }}
                </div>
              </b-dropdown>
            </div>
          </div>

        </div>
      </div>
      <div class="w-full cursor-pointer text-secondary font-small-2 text-right mr-3 pb-1" @click="refetchDashboardData">
        <feather-icon
            icon="RefreshCwIcon"
            size="12"
        />
        <span v-if="refetchingData"> Loading...</span>
        <span v-else>      Reload Data
</span>
      </div>
      <div class="base-table-wrapper hide-scrollbar">
        <table class="table base-table">
          <thead>
          <tr>
            <th class="account-name-th">Account Name</th>
            <th v-for="column in visibleLocationsTableColumns"
                @click="sortColumn=column.key;sortType=sortType=='ASC'?'DESC':'ASC'"
                class="cursor-pointer"
                :key="'th_' + column.label">
              {{ column.label }}
              <svg
                  v-if="column.key === sortColumn"
                  xmlns="http://www.w3.org/2000/svg"
                  :style="'height: 20px;width:20px;transform: rotate('+(sortType === 'ASC'?180:0)+'deg)'"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"/>
              </svg>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="({ name, data }, key) in sortedLocationsData" :key="key">
            <transition name="fast-fade" mode="out-in">
              <td
                  v-if="!locationsDataLoading"
                  class="account-name-td overflow-hidden"
                  :class="{'is-above-kpi': data.is_above_kpi, 'is-not-above-kpi': data.is_above_kpi === false}"
              >
                <div class="account-initials">
                  {{ name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substr(0, 1) }}
                </div>
                <span class="account-name">{{ name }}</span>
              </td>
              <td v-else key="td_skeleton" class="account-name-td skeleton-wrapper">
                <div class="account-initials">
                  <skeleton loading="true" class="d-block"/>
                </div>
                <div class="account-name">
                  <skeleton loading="true" class="block"/>
                </div>
              </td>
            </transition>

            <td v-for="column in visibleLocationsTableColumns" :key="'td_' + column.label">
              <transition name="fast-fade" mode="out-in">
                <div v-if="!locationsDataLoading">
                  {{ getFormattedColumnDataValue(data, column) }}
                </div>
                <skeleton v-else loading="true" class="block"/>
              </transition>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Pagination -->

      </div>
      <div class="locations-data-footer">
        <div v-if="!locationsDataLoading" class="pagination-wrapper">
          <div class="pagination-controls">
            <b-pagination
                v-model="pagination.current_page"
                :total-rows="locationsData.length"
                :per-page="pagination.per_page"
                @change="pagination.current_page = $event.target.value"
                :aria-controls="'locations-data-table-card'"
                size="md"
                class="pagination-controls"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {BDropdown, BDropdownItem, BPagination, BDropdownDivider, BFormCheckbox, VBModal} from 'bootstrap-vue';
import {Skeleton} from "vue-loading-skeleton";
import {mapState} from 'vuex';

export default {
  name: 'LocationsDataTable',
  components: {
    Skeleton,
    BDropdown,
    BPagination,
    BDropdownItem,
    BFormCheckbox,
    BDropdownDivider
  },
  directives: {
    VBModal
  },
  data() {
    return {
      toggleKpiRulesLoading: false,
      refetchingData: false,
      sortColumn: "leads",
      sortType: "DESC",
      pagination: {
        current_page: 1,
        per_page: 10
      },
      perPageOptions: [10, 20, 50, 100]
    }
  },
  computed: {
    ...mapState('dashboard', [
      'visibleLocationsTableColumns',
      'locationsData',
      'locationsDataLoading',
      'keyPerformanceIndicators'
    ]),

    ...mapState('auth', ['user']),
    sortedLocationsData() {
      if (this.locationsDataLoading) {
        return [
          {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
        ];
      }
      // Sort locationsData by sortColumn and sortType
      let data = this.locationsData.sort((a, b) => {
        const aValue = a.data[this.sortColumn];
        const bValue = b.data[this.sortColumn];
        if (this.sortType === 'DESC') {
          return bValue - aValue;
        } else {
          return aValue - bValue;
        }
      });


      // Paginate
      return data.slice((this.pagination.current_page - 1) * this.pagination.per_page, this.pagination.current_page * this.pagination.per_page);
    }
  },
  methods: {
    setVisibleColumnsFromLocalStorage() {
      const persistedVisibleColumnsInStorage = localStorage.getItem('dashboard_visible_columns');

      this.$store.commit(
          'dashboard/SET_VISIBLE_LOCATIONS_TABLE_COLUMNS',
          persistedVisibleColumnsInStorage
              ? JSON.parse(persistedVisibleColumnsInStorage)
              : this.user.location_data_columns
      );
    },
    setPerPage(per_page) {
      this.pagination.per_page = per_page;
      this.pagination.current_page = 1;
      this.$refs.perPageDropdown.hide(true)
    },
    async deleteKPI(kpi) {
      const {isConfirmed} = await this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!isConfirmed) return;

      await this.$store.dispatch('dashboard/deleteKPI', kpi.id);

      this.$swal({
        icon: 'success',
        title: 'Deleted!',
        text: 'Your KPI has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      await this.$store.dispatch('dashboard/fetchLocationsData');
    },
    async refetchDashboardData() {
      this.refetchingData = true;
      await this.$store.dispatch('dashboard/refetchLocationsData');
      this.refetchingData = false;
    },
    isColumnVisible(column) {
      return !!this.visibleLocationsTableColumns.some(visibleColumn => {
        return visibleColumn.label === column.label;
      });
    },

    toggleColumnVisibility(column, checked) {
      if (checked && !this.isColumnVisible(column)) {
        return this.$store.commit(
            'dashboard/SET_VISIBLE_LOCATIONS_TABLE_COLUMNS',
            [...this.visibleLocationsTableColumns, column].sort((a, b) => a.order - b.order)
        );
      }
      this.$store.commit(
          'dashboard/SET_VISIBLE_LOCATIONS_TABLE_COLUMNS',
          this.visibleLocationsTableColumns.filter(visibleCol => visibleCol.label !== column.label)
      );
    },

    getFormattedColumnDataValue(data, column) {
      const colValue = data[column.key];

      if (colValue === null) return 'N/A';

      const valueMaxTwoDecimals = Math.round(colValue * 100) / 100;
      return valueMaxTwoDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async toggleKpi(kpi, isActive) {
      if (this.toggleKpiRulesLoading) return;
      this.toggleKpiRulesLoading = true;
      await this.$store.dispatch('dashboard/updateKPI', {
        ...kpi,
        is_active: isActive
      });
      await this.$store.dispatch('dashboard/fetchLocationsData');
      this.toggleKpiRulesLoading = false;
    },

    openCreateKpiModal() {
      this.$store.commit('dashboard/SET_EDITED_KPI', null);
      this.$nextTick(() => {
        this.$bvModal.show('kpi-form-modal');
      });
    },

    openEditKpiModal(kpi) {
      this.$store.commit('dashboard/SET_EDITED_KPI', kpi);
      this.$nextTick(() => {
        this.$bvModal.show('kpi-form-modal');
      });
    }
  },
}
</script>
