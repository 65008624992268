<template>
    <b-form-group
        v-if="label"
        :label="label"
        :label-for="selectId"
    >
        <validation-provider
            #default="{errors}"
            :rules="rules"
            :name="validationName"
        >
            <b-form-select
                v-if="!isVueSelect"
                :id="selectId"
                :class="selectClasses"
                :value="value"
                @input="val => $emit('input', val)"
                :state="errors.length && !value ? false : null"
                :options="options"
            />
            <v-select
                v-else
                :id="selectId"
                :class="`${selectClasses} ${errors.length && !value ? 'is-invalid' : ''}`"
                :value="value"
                @input="val => $emit('input', val)"
                :options="options"
            />
            <small class="text-danger" v-if="errors.length && !value && showErrorMsg">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>

    <validation-provider
        v-else
        #default="{errors}"
        :rules="rules"
        :name="validationName"
    >
        <b-form-select
            v-if="!isVueSelect"
            :id="selectId"
            :class="selectClasses"
            :value="value"
            @input="val => $emit('input', val)"
            :state="errors.length && !value ? false : null"
            :options="options"
        />
        <v-select
            v-else
            :id="selectId"
            :class="`${selectClasses} ${errors.length && !value ? 'is-invalid' : ''}`"
            :value="value"
            @input="val => $emit('input', val)"
            :options="options"
        />
        <small class="text-danger" v-if="errors.length && !value && showErrorMsg">{{ errors[0] }}</small>
    </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import {BFormGroup, BFormSelect} from "bootstrap-vue";
import {required, email} from '@validations';
import vSelect from 'vue-select';

export default {
    props: {
        label: {
            type: String,
            required: false,
        },
        selectId: {
            type: String,
            required: false,
        },
        rules: {
            type: String,
            default: 'required',
        },
        validationName: {
            type: String,
            required: false,
        },
        value: {
            required: false
        },
        options: {
            type: Array,
            default: () => []
        },
        selectClasses: {
            type: String,
            default: ''
        },
        isVueSelect: {
            type: Boolean,
            default: false
        },
        showErrorMsg: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ValidationProvider,
        BFormGroup,
        BFormSelect,
        vSelect
    },
    data() {
        return {
            // form validation rules
            required,
            email
        }
    },
}
</script>
