<template>
  <div class="dashboard-cards-wrapper mb-1">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 mb-2">
        <dashboard-card
            emoji="fire"
            title="Top Leads"
            data-type="leads"
            :rows-data="topStats.top_leads"
            :rows-data-loading="topStatsLoading"
        />
      </div>
      <div class="col-12 col-lg-4 mb-2">
        <dashboard-card
            emoji="calendar"
            title="Top Appointments"
            data-type="appointments"
            :rows-data="topStats.top_appointments"
            :rows-data-loading="topStatsLoading"
        />
      </div>
      <div class="col-12 col-lg-4 mb-2">
        <dashboard-card
            emoji="money"
            title="Top Revenue"
            data-type="revenue"
            :rows-data="topStats.top_revenue"
            :rows-data-loading="topStatsLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DashboardCard from "@/layouts/components/front/dashboard/DashboardCard";

export default {
  name: 'DashboardCards',
  components: {
    DashboardCard
  },
  computed: {
    ...mapState('dashboard', ['topStats', 'topStatsLoading'])
  }
}
</script>
